import { PARTNERS_CONFIGS_MAP } from '@/assets/config/common/partner.config';
import { TableColumnStyle } from '@/models/layout/tableLayout';
import dayjs from 'dayjs';
import { FloatHelper, RoundType } from './floatHelper';

export const getEnumValueByKey = (obj: any, key: string): number | string => {
    return Object.keys(obj)[Object.values(obj).indexOf(key)];
};

export function numberComma(num: number) {
    if (isNaN(num)) return num;
    const comma = new RegExp(/(?<!\.\d+)\B(?=(\d{3})+\b)/g);
    return num.toString().replace(comma, ',');
}

export function numberToCommaWithPad(num: number, pad = 0) {
    // if (!!Intl && !!Intl.NumberFormat) {
    //     const nf = new Intl.NumberFormat('en-US', {
    //         minimumFractionDigits: pad,
    //         maximumFractionDigits: pad,
    //         roundingMode: 'floor',
    //     });
    //     return nf.format(num);
    // }

    return num.toFixed(pad).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export function comma2Number(comma: string) {
    return parseInt(comma.replaceAll(',', ''));
}

export const numericStyles = [
    TableColumnStyle.NumericText,
    TableColumnStyle.Numeric,
    TableColumnStyle.CommaNumeric,
    TableColumnStyle.CommaNumericWithSymbol,
    TableColumnStyle.CommaNumericWithNegativeSymbol,
    TableColumnStyle.Percentage,
    TableColumnStyle.Remark,
    TableColumnStyle.TextWithColor,
    TableColumnStyle.CommaNumericWithText,
    // TableColumnStyle.Partner,
];

export const alignCenterStyles = [TableColumnStyle.TextI18NWithColor];

export function parseCommissionType(text: string | number, style: TableColumnStyle) {
    let result: string | number = '';
    switch (style) {
        case TableColumnStyle.Date: {
            result = `${!!text ? dayjs(text).format('YYYY/MM/DD') : ''}`;
            break;
        }
        case TableColumnStyle.DateTime: {
            result = `${!!text ? dayjs(text).format('YYYY/MM/DD HH:mm:ss') : ''}`;
            break;
        }
        case TableColumnStyle.DateMonth: {
            result = `${!!text ? dayjs(text).format('YYYY-MM') : ''}`;
            break;
        }
        case TableColumnStyle.NumericText:
        case TableColumnStyle.Text: {
            result = `${text}`;
            break;
        }
        case TableColumnStyle.Remark: {
            result = `${text}`;
            break;
        }
        case TableColumnStyle.TextI18N:
        case TableColumnStyle.TextI18NWithColor: {
            result = `${text}`;
            break;
        }
        case TableColumnStyle.Percentage: {
            result = `${text}%`;

            break;
        }

        case TableColumnStyle.Numeric: {
            result = text;

            break;
        }
        case TableColumnStyle.CommaNumeric: {
            result = numberComma(FloatHelper.floatFixed(parseFloat(text + ''), RoundType.Floor, 0));
            break;
        }
        case TableColumnStyle.CommaNumericWithNegativeSymbol:
        case TableColumnStyle.CommaNumericWithSymbol: {
            result = text;
            break;
        }
        case TableColumnStyle.CommaNumericWithIcon: {
            result = text;
            break;
        }

        case TableColumnStyle.Partner: {
            const partnerId = Number(text);
            const config = PARTNERS_CONFIGS_MAP[partnerId];
            result = partnerId === 0 ? 'Lbl_Partner_All' : `Lbl_Partner_${config.text}`;
            break;
        }
    }

    return result;
}

export function toFirstUpperCase(str: string) {
    return str.replace(str[0], str[0].toUpperCase());
}

export function toFirstLowerCase(str: string) {
    return str.replace(str[0], str[0].toLowerCase());
}

export function parseStyleForTableContent(text: string, style: TableColumnStyle) {
    switch (style) {
        case TableColumnStyle.CommaNumeric:
    }
}

export const LIMIT_VALUE = 99_999_999;

export const numberListMapping = {
    n0to70000: [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 5000, 6000, 10000],
    nnito5000: [-LIMIT_VALUE, -5000, -4000, -3000, -2000, -1000, 0, 1000, 2000, 3000, 4000, 5000],
    nn5000toi: [-5000, -4000, -3000, -2000, -1000, 0, 1000, 2000, 3000, 4000, 5000, LIMIT_VALUE],
    n0to15000: [0, 500, 1000, 2000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 15000],
    n500toi: [500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 5000, 6000, 10000, LIMIT_VALUE],
} as const;

export const downloadCSV = (list: string[][], fileName = '') => {
    let csvString = '';

    list.forEach((rowItem) => {
        rowItem.forEach((colItem) => {
            csvString += `"${colItem}",`;
        });
        csvString += '\r\n';
    });

    csvString = 'data:text/csv;charset=utf-8,\ufeff' + csvString;
    const encodedUri = encodeURI(csvString);
    const anchor = document.createElement('a');
    anchor.setAttribute('href', encodedUri);
    anchor.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(anchor);
    anchor.click();
};

export function validatePasswordFormat(password: string, [min, max] = [6, 15]): boolean {
    const passwordRegex = new RegExp(`^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&.]{${min},${max}}$`);
    return passwordRegex.test(password);
}

export function validateVChatIdFormat(text: string): boolean {
    const regex = /^[A-Za-z0-9]{5,10}$/;
    return regex.test(text);
}

export function validateUserNameFormat(text: string): boolean {
    const regex = /^[A-Za-z0-9]{0,20}$/;
    return regex.test(text);
}
